


























































import { Component, Ref, Watch, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { ReportChartBaseComponent } from '@common-src/mixins/report-chart-base';
import { JTLTableQueryComponent } from '@common-src/components';
import { JYFCReportService, JYFCReportTableService, JYFCReportQueryModel, JYFCReportExportService } from '@/pages/sub-system/model/jyfc-model';
import { dateFormat, kilo, percentFormat } from '@common-src/filter';
import { PropertyEntityType, SubSystemType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';
import QuarantineRoomExportComponent from './quarantine-room-export.vue';

@Component({
    components: {
        QuarantineRoomExportComponent
    }
})
export default class ReportChartComponent extends Mixins(ReportChartBaseComponent, TableComponent) {
    percentFormat = percentFormat;
    /**
     * 页面标题
     */
    pageTitle: string = null;

    /**
     * 获取一级面包屑标题
     */
    rootBreadcrumb: any = { title: '', link: '' };

    /**
     * 图表的数据和配置
     */
    chartOptions: any = null;

    /**
     * 表格的数据和配置
     */
    tableOptions: any = null;

    /**
     * 图标的查询方法
     */
    chartService: Function = null;

    /**
     * 表格的导出数据服务
     */
    exportExcelService: Function = null;

    /**
     * 图标的搜索模型
     */
    queryModel: any = null;

    /**
     * 导出Excel的搜索模型
     */
    exportQueryModel: any = null;

    /**
     * 图表正在加载标识
     */
    chartLoading: boolean = false;

    /**
     * 图标的高度
     */
    chartHeight: number = 400;

    /**
     * 展开表格行的keys
     */
    tableExpandedRowKeys: Array<string> = [];

    /**
     * 图表查询组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME)
    queryComponent: JTLTableQueryComponent;

    mounted() {
        this.isAutoQuery = false;
        this.initTable({ listFunc: JYFCReportTableService });
        setTimeout(() => {
            const cHeight = document.body.clientHeight - 655;
            if (cHeight > 400) {
                this.chartHeight = cHeight;
            }
            this.initChart();
        }, 100);
    }

    @Watch('$route')
    routeChange() {
        this.resetChart();
        this.initChart();
    }

    /**
     * 获取展示类型
     * 图表/表格
     */
    get IsChart(): boolean {
        return _.get(this.chartOptions, 'settings.type') !== 'table';
    }

    /**
     * 获取图表标题
     */
    get ChartTitle() {
        return _.get(this.chartOptions, 'title');
    }

    /**
     * 获取图表的高度
     */
    get ChartHeight() {
        if (_.get(this.chartOptions, 'height') < this.chartHeight) {
            return this.chartHeight;
        }
        return _.get(this.chartOptions, 'height') || this.chartHeight;
    }

    /**
     * 获取表格列
     */
    get TableColumns() {
        return [
            {
                title: '手机号',
                dataIndex: 'phone'
            },
            {
                title: '入住位置',
                dataIndex: 'locationName'
            },
            {
                title: '房间号',
                dataIndex: 'roomName'
            },
            {
                title: '来源',
                dataIndex: 'source'
            },
            {
                title: '入住时间',
                dataIndex: 'quarantineStart',
                customRender: (text, record) => {
                    return dateFormat(record.quarantineStart);
                }
            },
            {
                title: '实际离舱日期',
                dataIndex: 'actualQuarantineEnd',
                customRender: (text, record) => {
                    return dateFormat(record.actualQuarantineEnd);
                }
            },
            {
                title: '入住人数',
                dataIndex: 'roomCount'
            },
            {
                title: '住宿费',
                customRender: (text, record) => {
                    return kilo(record.accommodationCost);
                }
            },
            {
                title: '早餐费',
                customRender: (text, record) => {
                    return kilo(record.breakfastCost);
                }
            },
            {
                title: '午餐费',
                customRender: (text, record) => {
                    return kilo(record.lunchCost);
                }
            },
            {
                title: '晚餐费',
                customRender: (text, record) => {
                    return kilo(record.dinnerCost);
                }
            }
        ];
    }

    /**
     * 获取图表数据
     */
    get ChartData() {
        return _.get(this.chartOptions, 'data');
    }

    /**
     * 获取图表配置
     */
    get ChartSettings() {
        return _.get(this.chartOptions, 'settings');
    }

    /**
     * 获取图表配置
     */
    get ChartExtend() {
        return _.get(this.chartOptions, 'extend');
    }

    /**
     * 获取X轴的配置
     */
    get ChartXAxis() {
        return _.get(this.chartOptions, 'xAxis');
    }

    /**
     * 获取Y轴的配置
     */
    get ChartYAxis() {
        return _.get(this.chartOptions, 'yAxis');
    }

    /**
     * 获取图表图例的显示/隐藏
     */
    get ChartLegendVisible() {
        return _.get(this.chartOptions, 'legendVisible', true);
    }

    /**
     * 图标搜索按钮的点击事件
     */
    chartSearchClick() {
        if (this.queryComponent && !this.queryComponent.validate()) {
            return;
        }
        return Promise.all([this.getChartData(), this.getTableData()]);
    }

    /**
     * 获取图表数据
     */
    getChartData(): Promise<any> {
        if (!this.chartService) {
            return new Promise((res, rej) => {
                res(null);
            });
        }
        this.tableExpandedRowKeys = [];
        const queryParams = this.queryModel.toService();
        this.chartLoading = true;
        return this.chartService(queryParams.params).then(res => {
            this.chartOptions = res;
            this.tableExpandedRowKeys = _.get(this.chartOptions, 'tableExpandedRowKeys') || [];
            this.exportQueryModel = _.cloneDeep(queryParams.params);
        }).catch((err) => {
            if (this.chartOptions) {
                (this.$refs.jtlChart as any).clearEcharts();
                this.chartOptions.data = null;
            }
        }).finally(() => {
            // 延迟100ms显示echarts图表
            // 为了显示echarts初始化时的动画效果
            setTimeout(() => { this.chartLoading = false; }, 100);
        });
    }

    /**
     * 获取表格数据
     */
    getTableData(): Promise<any> {
        return this.getList();
    }

    /**
     * 导出Excel点击事件
     */
    exportExcelClick() {
        if (!this.exportExcelService) {
            this.showDeveloping();
            return;
        }
        return this.exportExcelService(this.exportQueryModel).then(res => {
            if (res && res.er !== -1) {
                this.showMessageError('导出Excel失败，请联系管理员');
            } else {
                this.showMessageSuccess('导出Excel成功');
            }
        });
        // (this.$refs.exportExcelDialog as QuarantineRoomExportComponent).dialogOpen(true);
    }

    /**
     * 切换显示数量
     */
    showNumber(value: any) {
        this.chartOptions.extend.series.label.show = value.target.checked;
        this.chartOptions.extend.series.showSymbol = value.target.checked;
        (this.$refs.jtlChart as any).dataHandler(false);
    }

    /**
     * 初始化图表的数据和配置
     */
    initChart() {
        this.queryModel = new JYFCReportQueryModel();
        this.chartService = JYFCReportService;
        this.exportExcelService = JYFCReportExportService;
        this.pageTitle = '财务概况';
        this.rootBreadcrumb = { title: '疫情防控', link: '/dashboard/fc-user-list' };
        // this.chartSearchClick();
        this.queryComponent.initQuery(this.queryModel);
    }

    /**
     * 重置图表数据和配置
     */
    private resetChart() {
        this.chartOptions = null;
        this.tableOptions = null;
        this.chartService = null;
        this.queryModel = null;
        this.exportQueryModel = null;
    }
}

